<template lang="pug">
.room-booking-query
    b-modal(:active.sync="ciModalActive" :width="370")
        .modal-grid
            p CHECK-IN
            b-datepicker(inline v-model="checkInDate" @input="closeCheckinDP" :min-date="seasonMinDate" :max-date="seasonMaxDate")
    b-modal(:active.sync="coModalActive" :width="370")
        .modal-grid
            p CHECK-OUT
            b-datepicker(inline v-model="checkOutDate" @input="closeCheckoutDP" :min-date="minCheckOutDate" :max-date="seasonMaxDate")

    .title-text
        p BOOK IT NOW
    .outer-grid
        .gi
            p.blacktext Check-In
            .custom-select(@click="showCheckinDP")
                input(readonly v-bind:value="ciDate")
        .gi
            p.blacktext CHECK-OUT
            .custom-select(@click="showCheckoutDP")
                input(readonly v-bind:value="coDate")
        //.gi
        //    p.blacktext GUESTS
        //    .custom-select
        //        select(v-model:value="guestCount")
        //            option(value="1") 1
        //            option(value="2") 2
        //            option(value="3") 3
        //            option(value="4") 4
        //            option(value="5") 5
        //            option(value="6") 6
        //            option(value="7") 7
        //            option(value="8") 8
        .gi.center
            .custom-button-dark(@click="searchBooking") Check Availability

</template>

<style scoped>
.room-booking-query {
    background-color: #28e5c5;
    padding: 2rem;
}
.modal-grid {
    display: grid;
    place-items: center;
}
.outer-grid {
    display: grid;
    grid-gap: 1.5rem;
    place-items: center;
}
.title-text {
    text-align: center;
    padding-bottom: 2rem;
}
.title-text p {
    font-size: 22px;
    font-family: ModestoOpenInfill, 'sans-serif';
    font-weight: 400;
    letter-spacing: 0.2em;
}
.gi {
    text-align: center;
}
.gi p {
    padding-bottom: 0.25rem;
}

.room-booking-query .gi:last-child {
    padding-bottom: 0;
}
.center {
    text-align: center;
}

.custom-select {
    position: relative;
    display: block;
    min-width: 240px;
    border: 2px solid black;
}

.custom-select select, .custom-select input {
    font-family: "Open Sans", sans-serif;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border-radius: 0;
    margin: 0;
    display: block;
    padding: 6px 8px 6px 12px;
    font-size: 18px;
}

.custom-select input:hover {
    cursor: default;
}

.custom-select:after {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 6px;
    width: 40px;
    height: 100%;
    line-height: 24px;
    font-family: "Font Awesome 5 Pro";
    content: "\f078";
    text-align: center;
    color: black;
    font-size: 14px;
    border-left: 2px solid black;
    z-index: 1;
    background: #28e5c5;
}

@media screen and (min-width: 769px) {
    .gi {
        text-align: left;
    }
    .gi p {
        padding-left: 3px;
    }
}

</style>


<script>
export default {
    name: "RoomBookingQuery",
    data () {
        return {
            checkInDate: new Date('2025-02-20'),
            checkOutDate: new Date('2025-02-25'),
            guestCount: 2,
            minNightStay: 5,
            ciModalActive: false,
            coModalActive: false,
            seasonMinDate: new Date('2024-01-01'),
            seasonMaxDate: new Date('2025-12-31')
        }
    },
    methods: {
        showCheckinDP: function () {
            this.ciModalActive = true;
        },
        closeCheckinDP: function () {
            this.ciModalActive = false;
            if (this.checkOutDate.getTime() < this.checkInDate.getTime()) {
                this.checkOutDate = new Date(this.checkInDate.getTime());
                this.checkOutDate.setDate(this.checkOutDate.getDate()+4);
            }
        },
        showCheckoutDP: function () {
            this.coModalActive = true;
        },
        closeCheckoutDP: function () {
            this.coModalActive = false;
        },
        formatDate: function (date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            // return [year, month, day].join('-');
            return [day, month, year].join('/');
        },
        searchBooking: function () {
            if (this.checkOutDate.getTime() < this.checkInDate.getTime()) {
                // They done goofed, re-open the checkout modal
                this.showCheckoutDP();
            } else {
                // Push to the /book page
                this.$router.push({
                    path: '/book',
                    query: {
                        dateFrom: this.formatDate(this.checkInDate),
                        nights: this.numNights
                    }
                });
            }
        }
    },
    computed: {
        minCheckOutDate: function () {
            let x = new Date(this.checkInDate.getTime());
            x.setDate(x.getDate()+this.minNightStay);
            return x;
        },
        ciDate: function () {
            return this.checkInDate.toLocaleDateString('en-US', {day: 'numeric', month: 'short', year: 'numeric'})
        },
        coDate: function () {
            return this.checkOutDate.toLocaleDateString('en-US', {day: 'numeric', month: 'short', year: 'numeric'})
        },
        numNights: function () {
            const oneDay = 24*60*60*1000;
            return Math.round(Math.abs((this.checkInDate.getTime() - this.checkOutDate.getTime())/oneDay));
        }
    }
}
</script>

