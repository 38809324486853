<template lang="pug">
#mainblock
    hero-banner
    bq-bar
    bi-bar
    rooms-bar
    features-bar
    feature-info-bar
    contact-bar
    gallery-bar

</template>

<style scoped>

</style>

<script>
import HeroBanner from "./HeroBanner";
import BookingQueryBar from "./BookingQueryBar";
import BasicAboutBar from "./BasicAboutBar";
import RoomsBar from "./RoomsBar";
import FeaturesBar from "./FeaturesBar";
import FeatureInfoBar from "./FeatureInfoBar";
import ContactBar from "./ContactBar";
import GalleryBar from "./GalleryBar";

export default {
    name: "Index",
    data () {
        return {
            ip_address: "N/A",
            server_response: "N/A"
        }
    },
    components: {
        'hero-banner': HeroBanner,
        'bq-bar': BookingQueryBar,
        'bi-bar': BasicAboutBar,
        'rooms-bar': RoomsBar,
        'features-bar': FeaturesBar,
        'feature-info-bar': FeatureInfoBar,
        'contact-bar': ContactBar,
        'gallery-bar': GalleryBar,
    },
    methods: {
        test_api: async function () {
            try {
                this.ip_address = await this.$api.test();
            } catch (e) {
                this.$log.error("ERROR GETTING IP ADDRESS!");
                this.$log.error(e);
                this.ip_address = "ERROR";
            }
        },
        test_server: async function () {
            try {
                this.server_response = await this.$api.test_server();
            } catch (e) {
                this.$log.error("Error calling server /test route");
                this.$log.error(e);
                this.server_response = "ERROR";
            }
        }
    }
}
</script>

