<template lang="pug">
.gallery-bar
    .title-bar
        a(href="https://www.instagram.com/starfall.lodge" target="_blank") Follow us on <i class="fab fa-instagram">

    .image-grid
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/jib_small.jpg) center', 'background-size': 'cover'}")
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/summer_night_small.jpg) center', 'background-size': 'cover'}")
        a.grid-image(href="https://www.instagram.com/p/Bzcuv8KJ-eR/" target="_blank")
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/keys_insta.jpg) center', 'background-size': 'cover'}")
        a.grid-image(href="https://www.instagram.com/p/BzxDFCVpx_e/" target="_blank")
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/boots_insta.jpg) center', 'background-size': 'cover'}")
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/tottsuan_small.jpg) center', 'background-size': 'cover'}")
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/telescope_small.jpg) center', 'background-size': 'cover'}")
        a.grid-image(href="https://www.instagram.com/p/BzkI4pmJaSs/" target="_blank")
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/totoro_insta.jpg) center', 'background-size': 'cover'}")
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/sakura_small.jpg) center', 'background-size': 'cover'}")
        .grid-image
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/nff_small.jpg) center', 'background-size': 'cover'}")
        a.grid-image(href="https://www.instagram.com/p/B0CkgyWgvWf/" target="_blank")
            .tmp(:style="{background: 'url(https://s3.starfall-lodge.com/img/dog_insta.jpg) center', 'background-size': 'cover'}")

</template>

<style scoped>
.gallery-bar {
    padding-top: 2rem;
}
.title-bar {
    text-align: center;
    padding: 1rem;
}
.title-bar a {
    font-size: 16px;
    font-family: ModestoOpenInfill, 'sans-serif';
    font-weight: 600;
    letter-spacing: 0.2em;
    color: #28e5c5;
    text-transform: uppercase;
}
.title-bar a:hover {
    text-decoration: underline;
}
.image-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    user-select: none;
}
.image-grid::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}

.image-grid > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
}
.grid-image {
    max-width: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
}
.tmp {
    width: 100%;
    height: 100%;
    transition: transform .2s;
}
.tmp:hover {
    transform: scale(1.1);
    cursor: pointer;
}

@media screen and (min-width: 769px) {
    .image-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}



</style>

<script>
export default {
    name: "GalleryBar",
}
</script>

