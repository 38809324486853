<template lang="pug">
.footer-bar
    .container
        .outer-grid
            .address
                p.greentext Starfall Lodge
                p
                    i.greyicon.far.fa-phone
                    span.greytext (+81) 261-85-2648
                p
                    i.greyicon.far.fa-envelope
                    span.greytext bookings@starfall-lodge.com
                p
                    i.greyicon.far.fa-map-marker
                    span.greytext 〒399-9301 長野県北安曇郡白馬村北城5839-1
            img(src="https://s3.starfall-lodge.com/logo/icon.svg")

        p.tiny-footer-text Copyright {{currentYear}} Starfall Lodge. Website development by <a href="https://tallco.de" target="_blank">tallcode</a>. Design by Hannah Sutton.
</template>

<style scoped>
.footer-bar {
    text-align: center;
    padding: 2rem 1rem 0 1rem;
}
.outer-grid {
    display: grid;
    grid-auto-columns: 1fr;
    align-items: center;
}
.outer-grid img {
    min-height: 100px;
    height: 100%;
    justify-self: center;
}

.address .greytext {
    font-size: 14px;
}

.tiny-footer-text {
    text-align: center;
    color: #afafaf;
    font-family: 'Open Sans', 'sans-serif';
    font-weight: 400;
    font-size: 11px;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
}
.tiny-footer-text a {
    color: #28e5c5;
}
.tiny-footer-text a:hover {
    color: #28e5c5;
    text-decoration: underline;
}

@media screen and (min-width: 769px) {
    .footer-bar {
        padding: 2rem 4rem 0 4rem;
        text-align: left;
    }
    .outer-grid {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        align-items: center;
    }
    .outer-grid img {
        justify-self: end;
    }
}

</style>

<script>
export default {
    name: "FooterBar",
    computed: {
        currentYear: function () {
            let d = new Date();
            return d.getFullYear();
        }
    }
}
</script>

