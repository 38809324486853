<template lang="pug">
.basicAboutBar
    .outer-grid
        .about
            p.greentext.
                STARFALL IS A WARM, COZY LODGE IN THE HEART OF HAKUBA; THE PERFECT PLACE TO RELAX BETWEEN EPIC DAYS RIDING THE JAPANESE POWDER.
            p.basictext.
                Walking distance from restaurants, cafes, bars, supermarkets and the Happo Bus Terminal, where you can catch a ride to any of the 11 resorts in the Hakuba Valley. We'll help out wherever we can to make sure you have the best trip ever!
        .lodge-image

</template>

<style scoped>
.basicAboutBar {
    background-color: #f3f3f3;
}

.outer-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.about {
    text-align: center;
    justify-self: end;
    align-self: center;
    padding: 2rem;
    display: grid;
    grid-gap: 2rem;
}

.lodge-image {
    place-self: center;
    background-image: url("https://s3.starfall-lodge.com/img/front_door_short.jpg");
    background-size: cover;
    background-position: center;
    height: 100%;
    min-width: 100%;
    min-height: 50vh;
}


@media screen and (min-width: 769px) {
    .outer-grid {
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
    }
    .lodge-image {
        min-height: 30vw;
    }
    .about {
        padding: 2rem;
    }
}
@media screen and (min-width: 1024px) {
    .about {
        max-width: 90%;
    }
    .about {
        padding: 4rem;
    }
}


</style>

<script>
export default {
    name: "BasicAboutBar"
}
</script>

