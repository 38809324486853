<template lang="pug">
#mainblock
    room-hero-banner(roomName="King / Twin" roomImg="https://s3.starfall-lodge.com/img/dt_king_short.jpg")
    .features-section
        .container
            .room-grid
                .bq
                    room-booking-query
                .gal
                    image-gallery(:imageSet="imageSet")
                .room-info
                    p.titletext King / Twin
                    p.blacktext From ¥29,900 per night
                    .tag-grid
                        feature-tag(firstText="Bed" secondText="1 Double / 2 Singles")
                        feature-tag(firstText="Max" secondText="2 people")
                        feature-tag(firstText="Breakfast" secondText="included")
                        feature-tag(firstText="Essentials" secondText="included")
                        feature-tag(firstText="Wifi" secondText="super fast")
                        feature-tag(firstText="Pickup & Dropoff" secondText="yes")
                    .info-text
                        p.normaltext.
                            All of our private rooms can be configured as either two single beds or one big king,
                            and come with plenty of wardrobe space, linen and toasty heating. Bathrooms are
                            shared with all guests in the lodge, as is our roomy communal kitchen where you're
                            welcome to cook up a feed if your wallet needs a break from all the amazing food
                            options nearby.
                        p.normaltext.
                            The living room is the heart of Starfall, with plenty of comfy couches and bean bags
                            to chill on and share tales of your day over a few drinks. Meet and bond with your
                            housemates, play some Mario Kart in the games corner or just zen out to the mountain views. If you're keen to party when we close things up at around 10:30PM, you
                            can grab a roadie and wander up to Happo where there's plenty of great nightlife to
                            choose from!
                        p.normaltext.
                            All bookings include a daily continental breakfast, pickup and drop-off for arrival and
                            departure, and the best service we can offer from the moment you book until we
                            see you off! If there's anything more you need to know just contact us, we're happy to help!
    contact-bar
    gallery-bar

</template>

<style scoped>
.features-section {
    width: 100%;
    background-color: #fff;
}
.room-grid {
    display: grid;
    padding-bottom: 1rem;
}
.tag-grid {
    padding: 2rem 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
}
.info-text {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
}
.room-info > p {
    text-align: center;
}

@media screen and (min-width: 769px) {
    .room-grid {
        padding: 2rem;
        grid-gap: 2rem;
        grid-auto-flow: column;
        grid-template-columns: 1fr 310px;
    }
    .gal {
        grid-column-start: 1;
    }
    .room-info {
        grid-column-start: 1;
    }
    .room-info > p {
        text-align: left;
    }
    .tag-grid {
        grid-template-columns: 1fr 1fr;
        padding: 2rem 0 2rem 0;
    }
    .info-text {
        padding: 0;
    }
}
</style>

<script>
import RoomHeroBanner from "./widgets/RoomHeroBanner";
import ImageGallery from "./widgets/ImageGallery";
import RoomBookingQuery from "./widgets/RoomBookingQuery";
import FeatureTag from "./widgets/FeatureTag";
import ContactBar from "../ContactBar";
import GalleryBar from "../GalleryBar";
export default {
    name: "DoubleKing",
    components: {
        GalleryBar,
        ContactBar,
        FeatureTag,
        RoomBookingQuery,
        ImageGallery,
        RoomHeroBanner
    },
    data: function () {
        return {
            imageSet: [
                'https://s3.starfall-lodge.com/img/dt_king2_med.jpg',
                'https://s3.starfall-lodge.com/img/dt_king3_med.jpg',
                'https://s3.starfall-lodge.com/img/dt_king_med.jpg',
                'https://s3.starfall-lodge.com/img/dt_twin_med.jpg',
                'https://s3.starfall-lodge.com/img/kitchen_med.jpg',
                'https://s3.starfall-lodge.com/img/lounge1_med.jpg',
                'https://s3.starfall-lodge.com/img/shower_med.jpg',
                'https://s3.starfall-lodge.com/img/toilets_med.jpg',
            ]
        }
    }
}
</script>

