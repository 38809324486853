<template lang="pug">
.room-hero
    .hero-image(:style="{ background: backgroundUrl, 'background-size': 'cover'}")
    .hero-text
        p {{roomName}}
</template>

<style scoped>
.room-hero {
    display: grid;
    height: 300px;
    user-select: none;
}
.hero-image {
    grid-area: 1 / 1;
    width: 100%;
    overflow: hidden;
}
.hero-text {
    width: 100%;
    grid-area: 1 / 1;
    text-align: center;
    color: black;
    z-index: 1;
    display: grid;
    place-items: center;
}
.hero-text p {
    font-family: 'ModestoOpenInfill', sans-serif;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0.2em;
    color: #fff;
}

@media screen and (min-width: 769px) {
    .room-hero {
        height: 400px;
    }
    .hero-text p {
        font-size: 48px;
    }
}
</style>

<script>
export default {
    name: "RoomHeroBanner",
    props: [
        'roomName',
        'roomImg'
    ],
    computed: {
        backgroundUrl: function () {
            return `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${this.roomImg}) center`;
        }
    }
}
</script>

