<template lang="pug">
.gallery-page
  .image-modal(v-if="modal_visible" @click="modal_visible = false")
    img(:src="modal_image")

  .gallery-grid
    img(v-for="p in gallery_images" :src="p" @click="setModalImage(p)")


  contact-bar
</template>

<style scoped>
.title-text {
    display: grid;
    place-items: center;
    padding: 1rem;
}
.title-text p {
    font-family: ModestoOpenInfill, sans-serif;
    font-size: 36px;
}
.gallery-grid {
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 0;
    column-count: 1;
    column-gap: 2rem;
    column-fill: balance;
}

.gallery-grid img {
    width: 100% !important;
    height: auto !important;
    padding-bottom: 2rem;
    transition: transform .2s;
}

.gallery-grid img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: grid;
    place-items: center;
    background-color: rgba(255,255,255,0.4);
    z-index: 100;
}

.image-modal img {
    max-height: 90vh;
}


@media screen and (min-width: 769px) {
    .gallery-grid {
        column-count: 3;
    }
}

</style>

<script>
import ContactBar from "./ContactBar";

import AWS from 'aws-sdk/global';
import S3_CORE from 'aws-sdk/clients/s3';

const S3_BUCKET_NAME = "s3.starfall-lodge.com";

// AWS creds setup
AWS.config.region = "us-east-1";
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:d5d7e469-9095-4a81-b307-06a6231e2209"
});

// Create a new service object
const s3 = new S3_CORE({
    apiVersion: '2006-03-01',
    params: {Bucket: S3_BUCKET_NAME},
});

export default {
    name: "Gallery",
    components: {ContactBar},
    data: function () {
        return {
            gallery_images: [],
            modal_image: "",
            modal_visible: false,
        }
    },
    mounted: async function () {
        // Load image URL's from S3 bucket
        let photos = await s3.listObjects({
            Prefix: 'gallery/',
        }).promise();
        // console.log(photos);

        // sort by date modified

        for (let p of photos.Contents.sort((a, b) => a.LastModified.getTime() > b.LastModified.getTime() ? -1 : 1)) {
            if (p.Key != 'gallery/') {
                this.gallery_images.push(`https://s3.starfall-lodge.com/${p.Key}`);
            }
        }
        // console.log(this.gallery_images);
    },
    methods: {
        setModalImage: async function (i) {
            this.modal_image = i;
            this.modal_visible = true;
        }
    }
}
</script>

