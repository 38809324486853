<template lang="pug">
.navbar.is-sfdark.is-transparent
    .container
        .navbar-brand
            router-link.navbar-item(tag='div' to="/")
                .mobile-logo
                    img(src="https://s3.starfall-lodge.com/logo/icon.svg")
                    div Starfall Lodge
                .desktop-logo
                    img(src="https://s3.starfall-lodge.com/logo/horizontal.svg")

            .navbar-burger(@click="triggerMobileNav" :class="mobileNavActiveClass")
                span
                span
                span
        .navbar-menu(:class="mobileNavActiveClass")
            .navbar-start
            .navbar-end
                router-link.navbar-item(to="/" @click.native="triggerMobileNav") Home
                router-link.navbar-item(to="/about" @click.native="triggerMobileNav") About
                router-link.navbar-item(to="/rooms" @click.native="triggerMobileNav") Rooms
                router-link.navbar-item(to="/gallery" @click.native="triggerMobileNav") Gallery
                router-link.navbar-item(to="/livecam" @click.native="triggerMobileNav") Livecam
                router-link.navbar-item(to="/book" @click.native="triggerMobileNav") Book


</template>

<style scoped>
.navbar-item {
    font-family: 'ModestoOpenInfill', 'Open Sans', sans-serif;
    font-size: 14px;
    letter-spacing: 3px;
}

.navbar-brand .mobile-logo {
    font-size: 18px;
    letter-spacing: 0.2em;
    color: #28e5c5;
}

.desktop-logo img {
    display: none;
}

.mobile-logo {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
}
.mobile-logo img {
    height: 24px;
    padding-right: 0.5rem;
}

/* Additional padding in the nav for desktop viewers*/
@media screen and (min-width: 1215px) {
    .navbar {
        min-height: 7.25rem;
    }

    .navbar-brand .mobile-logo{
        display: none;
    }

    .navbar > .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .navbar-brand > .navbar-item {
        padding-top: 0.75rem;
        padding-bottom: 0.25rem;
        cursor: pointer;
    }

    .desktop-logo img {
        height: 100px;
        display: block;
        max-height: unset;
        max-width: unset;
    }

    a.navbar-item:hover {
        color: #28e5c5 !important;
    }
}

</style>

<script>
export default {
    name: "Navigation",
    data () {
        return {
            brand: "Starfall Lodge",
            mobileNavActive: false,
        }
    },
    methods: {
        triggerMobileNav: function () {
            this.mobileNavActive = !this.mobileNavActive;
        }
    },
    computed: {
        mobileNavActiveClass: function () {
            return {
                'is-active': this.mobileNavActive
            }
        }
    }
}
</script>

