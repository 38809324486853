<template lang="pug">
.feature-info-bar
    .outer-grid
        .container
            .feature
                .feature-text
                    p.greentext Plenty of room,
                    p.greentext Flexible options
                    p.basictext.
                        Starfall features 12 freshly renovated guests rooms with plenty
                        of wardrobe space, linen and toasty heating. All of our private rooms can be
                        configured as either two single beds or one big king, and the
                        spacious dorm rooms include all of the same benefits at a budget traveller rate
                .feature-image
                    img(src="https://s3.starfall-lodge.com/img/towel_small.jpg")
        .container
            .feature.rtl-desktop
                .feature-text
                    p.greentext Stellar mountain views
                    p.basictext.
                        Located dead-center in Hakuba, every room in the lodge boasts resort
                        views of Happo One, Hakuba 47 and the Highland resort

                .feature-image
                    img(src="https://s3.starfall-lodge.com/img/obs_small.jpg")
        .container
            .feature
                .feature-text
                    p.greentext Serious about sustainability
                    p.basictext.
                        Our winters are at risk due to climate change, but we're doing our
                        best to combat it where we can. The lodge is run on 100% renewable energy,
                        we minimise our waste and recycle as much as possible, and we match any
                        guest donations to Protect Our Winters!
                .feature-image
                    img(src="https://s3.starfall-lodge.com/img/bros_small.jpg")
</template>

<style scoped>
.feature-info-bar {
    background-color: white;
}
.outer-grid {
    display: grid;
}
.outer-grid .container {
    width: 100%;
    text-align: center;
}
.feature {
    display: grid;
    min-height: 100px;
    place-items: center;
}
.feature-text {
    padding: 2rem;
}
.feature-text .basictext {
    padding-top: 1rem;
}


@media screen and (min-width: 769px) {
    .rtl-desktop {
        direction: rtl;
    }
    .feature {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
    .feature-image {
        padding: 1rem;
    }
    .outer-grid {
        padding: 1rem;
    }
}

</style>

<script>
export default {
    name: "FeatureInfoBar"
}
</script>

