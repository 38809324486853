<template lang="pug">
.rooms-page
    room-hero-banner(roomName="Our Rooms" roomImg="https://s3.starfall-lodge.com/img/towel_large.jpg")
    rooms-bar(:hideTitle="true")
    contact-bar
    gallery-bar
</template>

<style scoped>

</style>

<script>
import RoomHeroBanner from "./rooms/widgets/RoomHeroBanner"
import RoomsBar from "./RoomsBar";
import GalleryBar from "./GalleryBar";
import ContactBar from "./ContactBar";

export default {
    name: "Rooms",
    components: {RoomsBar, RoomHeroBanner, ContactBar, GalleryBar}
}
</script>

