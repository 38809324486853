<template lang="pug">
.image-gallery
    .primary-image
        img(:src="imageSet[selectedIndex]")
        .left-arrow(@click="decrementImage")
            i.fal.fa-chevron-left
        .right-arrow(@click="incrementImage")
            i.fal.fa-chevron-right
    .image-grid
        img(v-for="(gi, index) in imageSet" @click="changeSelectedImage(index)" :src="gi")

</template>

<style scoped>
.image-gallery {
    user-select: none;
}
.primary-image {
    width: 100%;
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
}
.primary-image img {
    grid-row: 1;
    grid-column: 1 / 4;
    place-self: center;
    max-height: 50vh;
}
.left-arrow, .right-arrow {
    grid-row: 1;
    display: grid;
    place-items: center;
    font-size: 56px;
    color: #28e5c5;
    cursor: pointer;
}
.left-arrow {
    grid-column: 1 / 2;
}
.right-arrow {
    grid-column: 3 / 4;
}
.image-grid {
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-content: start;
    padding-bottom: 1rem;
}

.image-grid img {
    max-height: 100px;
}

@media screen and (min-width: 769px) {
    .primary-image img {
        max-height: 625px;
    }
}

</style>

<script>
export default {
    name: "ImageGallery",
    props: ['imageSet'],
    data: function () {
        return {
            selectedIndex: 0
        }
    },
    methods: {
        changeSelectedImage: function (i) {
            this.selectedIndex = i;
        },
        incrementImage: function () {
            this.selectedIndex = (this.selectedIndex + 1) % this.imageSet.length;
        },
        decrementImage: function () {
            this.selectedIndex = (this.imageSet.length + this.selectedIndex - 1) % this.imageSet.length;
        }
    }
}
</script>

