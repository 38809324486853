<template lang="pug">
// Top image
.hero-banner
    .hero-text
        div
            p Ski Accommodation
            p Hakuba, Japan
            b-icon(icon="chevron-down" pack="fal")
    .hero-image
      video#bannerVid(autoplay muted loop)
        source(src="https://s3.starfall-lodge.com/img/Lodgepromo2.mp4" type="video/mp4")
</template>

<style scoped>
.hero-banner {
    overflow: hidden;
    display: grid;
    height: calc(100vh - 3.25rem);
    user-select: none;
}
.hero-image {
    grid-area: 1 / 1;
    width: 100%;
    overflow: hidden;
    background: url("https://s3.starfall-lodge.com/img/blue1.jpg") center;
    background-size: cover;
}
.hero-image > video {
  display: none;
}

.hero-text {
    width: 100%;
    grid-area: 1 / 1;
    text-align: center;
    color: black;
    z-index: 1;
    display: grid;
    align-items: end;
    justify-items: center;
}
.hero-text p {
    font-family: 'ModestoOpenInfill', sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.2em;
}
.hero-text div span {
    padding-top: 3rem;
    font-size: 22px;
    padding-bottom: 6rem;
}

@media screen and (min-width: 769px) {
    .hero-banner {
      height: auto;
    }
    .hero-image {
        background: none;
        margin: 0;
    }
    .hero-image video {
      display: inline;
      width: 100%;
      margin: 0;
    }
    .hero-text {
        color: #fff;
        place-items: center;
        text-shadow:
          3px 3px 0 #000,
          2px 2px 0 #000,
          4px 4px 0 #000,
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000;
    }
    .hero-text p {
        font-family: 'ModestoOpenInfill', sans-serif;
        font-size: 48px;
        letter-spacing: 0.2em;
    }
    .hero-text div span {
        font-size: 36px;
        padding-bottom: 0rem;
    }
}
</style>

<script>
export default {
    name: "HeroBanner",
    activated: function () {
        const video = document.querySelector("#bannerVid");
        video.play();
    }
}
</script>

