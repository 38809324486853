<template lang="pug">
#app
    app-nav
    #page-content
        keep-alive
            router-view
    footer-bar
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

@font-face {
    font-family: 'ModestoOpenInfill';
    font-style: normal;
    font-weight: 400;
    src: url('https://s3.starfall-lodge.com/fonts/modesto_open_inline.woff2') format('woff2'),
    url('https://s3.starfall-lodge.com/fonts/modesto_open_inline.woff') format('woff');
}

// Set your colors
$primary: #8c67ef;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);
$sfgreen: #28e5c5;
$sfgreen-invert: findColorInvert($sfgreen);
$sfdark: #0d1221;
$sfdark-invert: findColorInvert($sfdark);
$sfgrey: #f3f3f3;
$sfgrey-invert: findColorInvert($sfgrey);

// Set nav to only switch to burger below tablet screens
$navbar-breakpoint: $tablet;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
        "white": ($white, $black),
        "black": ($black, $white),
        "light": ($light, $light-invert),
        "dark": ($dark, $dark-invert),
        "primary": ($primary, $primary-invert),
        "info": ($info, $info-invert),
        "success": ($success, $success-invert),
        "warning": ($warning, $warning-invert),
        "danger": ($danger, $danger-invert),
        "twitter": ($twitter, $twitter-invert),
        "sfgreen": ($sfgreen, $sfgreen-invert),
        "sfdark": ($sfdark, $sfgrey),
        "sfgrey": ($sfgrey, $sfgrey-invert)
);
$body-background-color: $sfdark;

// Tweak the Bulma navbar burger breakpoint
$navbar-breakpoint: 1024px;

// Create some text formats
.greentext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.3em;
    color: $sfgreen;
    text-transform: uppercase;
}
.greenicon {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.3em;
    color: $sfgreen;
    padding-right: 0.5rem;
}
.greencolor {
    color: $sfgreen;
}

.whitetext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.15em;
    color: white;
    text-transform: uppercase;
}

.darktext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.15em;
    color: $sfdark;
    text-transform: uppercase;
}
.blacktext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.3em;
    color: #000;
    text-transform: uppercase;
}
.redtext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.15em;
    color: red;
    text-transform: uppercase;
}

.basictext {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: black;
}
.greentitle {
    font-family: ModestoOpenInfill, sans-serif;
    font-size: 36px;
    letter-spacing: 0.2em;
    color: $sfgreen;
    text-transform: uppercase;
}
.greytext {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #afafaf;
}
.greyicon {
    font-size: 16px;
    color: #afafaf;
    padding-right: 0.5rem;
    width: 24px;
}
.titletext {
    font-family: 'ModestoOpenInfill', sans-serif;
    font-size: 36px;
    font-weight: 300;
    letter-spacing: 0.2em;
    color: black;
}
.custom-button {
    background-color: #28e5c5;
    width: 100px;
    height: 36px;
    line-height: 36px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.15em;
    cursor: pointer;
}
.custom-button-dark {
    background-color: black;
    padding-top: 2px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 40px;
    line-height: 36px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.15em;
    cursor: pointer;
    text-transform: uppercase;
}

img {
    display: block;
}
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import navigation from './components/Navigation';
import FooterBar from "./components/FooterBar";

export default {
    name: "app",
    data () {
        return {
            msg: 'Welcome to the VUE JS template'
        }
    },
    components: {
        'app-nav': navigation,
        'footer-bar': FooterBar,
    },
    methods: {
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec*1000);
            });
        },
        test: async function () {
            await this.waitSecondsAsync(2);
            console.log("Finished waiting");
        }
    }
}
</script>
