<template lang="pug">
.features-bar
    .container
        .feature-grid
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-breakfast.svg")
                p.whitetext Breakfast Included
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-pickup.svg")
                p.whitetext Pickup & Dropoff
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-helpful.svg")
                p.whitetext Helpful Staff
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-bedroom.svg")
                p.whitetext 14 Bedrooms
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-kitchen.svg")
                p.whitetext Communal Kitchen
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-bathroom.svg")
                p.whitetext Shared Bathrooms
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-commons.svg")
                p.whitetext Cozy Commons
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-games.svg")
                p.whitetext Games Corner
            .feat
                img(src="https://s3.starfall-lodge.com/icon/icon-observatory.svg")
                p.whitetext Observatory
</template>

<style scoped>
.features-bar {
    background: url("https://s3.starfall-lodge.com/img/background-snowboarder-2000px.jpg") center;
    background-size: cover;
}

.feature-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    justify-items: center;
    padding: 2rem;
    grid-column-gap: 1rem;
    grid-row-gap: 2rem;
    text-align: center;
}

.feat {
    display: grid;
    place-items: center;
}

.feat:last-child {
    grid-column: span 2;
}

.feat img {
    width: 4rem;
    padding-bottom: 0.25rem;
}

@media screen and (min-width: 769px) {
    .feature-grid {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: start;
        justify-items: center;
        padding: 4rem;
        grid-gap: 7vw;
        text-align: center;
    }
    .feat img {
        width: 7rem;
        padding-bottom: 1.5rem;
    }
    .feat:last-child {
        grid-column: span 1;
    }
}
</style>

<script>
export default {
    name: "FeaturesBar"
}
</script>

