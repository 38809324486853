<template lang="pug">
.feature-tag
    .tick
        i.fal.fa-check
        p.blacktext {{firstText}}
    p.greentext {{secondText}}

</template>

<style scoped>
.feature-tag {
    padding: 0.5rem;
    border: 2px solid #28e5c5;
    display: grid;
    grid-auto-flow: column;
    place-items: start;
}
.tick {
    display: grid;
    grid-auto-flow: column;
}
.tick i {
    color: #28e5c5;
    font-weight: 400;
    font-size: 24px;
    padding-right: 0.5rem;
}
p {
    line-height: 24px;
    font-size: 12px;
    letter-spacing: 0.15em;
}
p.greentext {
    justify-self: end;
}
</style>

<script>
export default {
    name: "FeatureTag",
    props: ['firstText', 'secondText']
}
</script>

