<template lang="pug">
.contact-bar
    b-modal(:active.sync="modalActive" :width="370")
        .box.has-text-centered
            .redtext Sorry, something went wrong
            p.basictext Please send us an email the old fashioned way to
            p.basictext <b>bookings@starfall-lodge.com</b>

    .container
        .header
            p.greentitle GET IN TOUCH
        .outer-grid
            .info-panel
                p.greentext (+81) 261-85-2648
                p.greentext bookings@starfall-lodge.com
                p.greentext.pad-top Starfall Lodge
                p.greytext 5839-1 Hokujo
                p.greytext Hakuba
                p.greytext Nagano, 399-9301
                img.pad-top(src="https://s3.starfall-lodge.com/logo/snowboarder.svg")
            form.contact-form(@submit.prevent="sendEnquiryEmail")
                .field.is-grouped
                    .control.has-icons-left.is-expanded
                        input.input(required type="text" placeholder="Name" v-model="enquiryName")
                        span.icon.is-left
                            i.fas.fa-user
                .field.is-grouped
                    .control.has-icons-left.is-expanded
                        input.input(required type="email" placeholder="Email Address" v-model="enquiryEmail")
                        span.icon.is-left
                            i.fas.fa-envelope
                .field.is-grouped
                    .control.is-expanded
                        textarea.textarea(required v-on:keydown.enter="$event.stopPropagation()" placeholder="Your Enquiry" rows="5" v-model="enquiryMessage")
                .field.is-grouped.is-grouped-centered
                    .control
                        button.custom-button(:disabled="sendEnquiryButton.disabled" type="submit")
                            span(v-if="!enquiryPending") {{sendEnquiryButton.text}}
                            .loader(v-else)



</template>

<style scoped>
.contact-bar {
    padding: 1rem;
}
.header {
    width: 100%;
    padding: 1rem;
    text-align: center;
}
.outer-grid {
    display: grid;
}
.info-panel {
    grid-row-start: 2;
    text-align: center;
    line-height: 30px;
    padding: 1rem;
}
.pad-top {
    padding-top: 2rem;
}
.info-panel img {
    max-height: 120px;
}
.contact-form {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
}
.loader {
    margin: 0 auto;
}
.redtext i {
    padding-left: 1rem;
}

@media screen and (min-width: 769px) {
    .outer-grid {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
    }
    .info-panel {
        grid-row-start: unset;
    }
}
</style>

<script>
export default {
    name: "ContactBar",
    data: function () {
        return {
            enquiryName: "",
            enquiryEmail: "",
            enquiryMessage: "",
            enquiryPending: false,
            enquirySent: false,
            modalActive: false,
        }
    },
    methods: {
        sendEnquiryEmail: async function () {
            this.enquiryPending = true;
            this.$log.debug("Got contact form submit!");
            this.$log.debug(`name: ${this.enquiryName}, email: ${this.enquiryEmail}, msg: ${this.enquiryMessage}`);
            // Send the email
            try {
                await this.$api.send_enquiry({
                    name: this.enquiryName,
                    email: this.enquiryEmail,
                    msg: this.enquiryMessage
                });
                this.enquiryPending = false;
                this.enquirySent = true;
                this.resetFields();
            } catch (e) {
                this.$log.error(`Failed to send enquiry email!`);
                this.$log.error(e);
                this.modalActive = true;
                this.enquiryPending = false;
            }
        },
        closeModal: function () {
            this.modalActive = false;
        },
        resetFields: function () {
            this.enquiryName = "";
            this.enquiryMessage = "";
            this.enquiryEmail = "";
        }
    },
    computed: {
        sendEnquiryButton: function () {
            return {
                text: this.enquirySent ? 'SENT!' : 'SEND',
                disabled: this.enquiryPending
            }
        }
    }
}
</script>

