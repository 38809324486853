<template lang="pug">
.about-page
    room-hero-banner(roomName="About" roomImg="https://s3.starfall-lodge.com/img/lodge_short.jpg")
    .about-area
        .container
            .about-grid
                .about-text
                    p.greentext The lodge
                    p.basictext.
                        Once a Japanese style inn and restaurant, this freshly renovated 14 bedroom lodge
                        is now a warm, cozy getaway in the perfect location for people out to make the most
                        of the amazing snow resorts Hakuba has to offer.
                    p.basictext.extra-pad-bottom.
                        We live in the lodge full time, and strive to help out with everything we can to make
                        your trip as smooth and enjoyable as possible. Looking for vegetarian lunch
                        options? Not sure what bus you need to catch for the Snow Monkeys? Need to know
                        where the best tree runs are on a powder day? We've got you covered.

                    p.greentext The neighborhood
                    p.basictext.extra-pad-bottom.
                        We're smack bang in the middle of town, close to everything you'll need for your
                        stay. There's four restaurants and a bottle shop right on the doorstep, and tons
                        more within a 10-15 minute walk (or a cheap taxi ride). If you're nursing a caffeine
                        addiction there's three amazing coffee spots nearby to choose from, all of which
                        make a killer cappuccino (hard to come by in Japan!). For supplies the A-Coop supermarket is a
                        5 minute walk, and if you need to soak after a long day riding the gorgeous Mimizuku Onsen is just around the corner

                    p.greentext Map
                    <iframe width="600" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJqWbROf_N918RJzqKtfaobjQ&key=AIzaSyC6LWA9UC8dmmn4HaZGv6Gegpgx8nVLrFY" allowfullscreen></iframe>
                .about-images
                    img(src="https://s3.starfall-lodge.com/img/lodge_small.jpg")
                    img(src="https://s3.starfall-lodge.com/img/bros_small.jpg")
                    img(src="https://s3.starfall-lodge.com/img/towel_small.jpg")

    contact-bar
    gallery-bar

</template>

<style scoped>
.about-area {
    background: #fff;
}
.about-grid {
    display: grid;
}
.about-text {
    padding: 1rem;
}
.about-text p {
    padding-bottom: 1rem;
}
.about-text iframe {
    width: 600px;
    height: 450px;
}
.about-images {
    padding: 1rem;
}
.about-images img {
    padding-bottom: 1rem;
}
p.basictext.extra-pad-bottom {
    padding-bottom: 3rem;
}

@media screen and (min-width: 769px) {
    .about-grid {
        padding: 3rem;
        grid-auto-flow: column;
    }
}
</style>

<script>
    import RoomHeroBanner from "./rooms/widgets/RoomHeroBanner";
    import ContactBar from "./ContactBar";
    import GalleryBar from "./GalleryBar";
    export default {
        name: "About",
        components: {GalleryBar, ContactBar, RoomHeroBanner}
    }
</script>

