<template lang="pug">
.livecam-page
    .livecam-area
        .container
            .title-text
                p LIVE CAM
            .sub-text
                p Updates every five minutes
                p Far left resort is <span class='greencolor'>Hakuba 47</span>, right side resort is <span class='greencolor'>Happo-One</span>
            .livecam-grid
                img(v-bind:src="imgSrc")

    contact-bar
    gallery-bar
</template>

<style scoped>
.livecam-area {
    background: #fff;
    padding-bottom: 2rem;
}
.title-text {
    display: grid;
    place-items: center;
    padding: 1rem;
}
.title-text p {
    font-family: ModestoOpenInfill, sans-serif;
    font-size: 36px;
}
.sub-text {
    padding-bottom: 1rem;
}
.sub-text p {
    font-size: 16px;
    font-family: ModestoOpenInfill, 'sans-serif';
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
}
</style>

<script>
import RoomHeroBanner from "./rooms/widgets/RoomHeroBanner"
import RoomsBar from "./RoomsBar";
import GalleryBar from "./GalleryBar";
import ContactBar from "./ContactBar";

const BASE_IMG_URL = "https://s3.amazonaws.com/s3.starfall-lodge.com/livecam/latest.jpg";

export default {
    name: "Rooms",
    components: {RoomsBar, RoomHeroBanner, ContactBar, GalleryBar},
    data: function () {
        return {
            imgSrc: BASE_IMG_URL,
            interval: '',
        }
    },
    mounted: function () {
        this.interval = setInterval(() => {
            this.imgSrc = `${BASE_IMG_URL}?${(new Date()).getTime()}`;
        }, 1000 * 60);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
}
</script>

