import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../components/Index';
import About from '../components/About';
import NotFound from '../components/NotFound';
import DoubleKing from '../components/rooms/DoubleKing';
import Quad from '../components/rooms/Quad';
import Dorm from "../components/rooms/Dorm";
import Rooms from "../components/Rooms";
import Book from "../components/book/Book";
import LiveCam from "../components/LiveCam";
import Gallery from "../components/Gallery";

const routes = [
    {
        path: '/',
        component: Index
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/book',
        component: Book
    },
    {
        path: '/rooms',
        component: Rooms
    },
    {
        path: '/gallery',
        component: Gallery
    },
    {
        path: '/rooms/dk',
        component: DoubleKing
    },
    {
        path: '/rooms/quad',
        component: Quad
    },
    {
        path: '/rooms/dorm',
        component: Dorm
    },
    {
        path: '/livecam',
        component: LiveCam
    },
    {
        path: '*',
        component: NotFound
    }
];

export default new Router({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});
